.portfolio__footer-content{
  font-family: var(--font-family);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem 30rem 3rem 30rem;
  margin-top: -1px;
  padding-top: 200px;
}

#smiley{
  width:3rem;
  margin-bottom: 1rem;
}


.portfolio__footer h1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.portfolio__footer p {
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    text-align: center;

}






@media  screen and (max-width: 1200px) {
  .portfolio__footer-content{
    padding: 5rem 20rem 3rem 20rem;
  }
  
}

@media  screen and (max-width: 950px) {
  .portfolio__footer-content{
    padding: 2rem 10rem 3rem 10rem;
  }
}


@media  screen and (max-width: 650px) {

  .portfolio__footer-content{
    padding: 2rem 4rem 3rem 4rem;
}
}



@media  screen and (max-width: 375px) {
  .portfolio__footer-content{
    padding: 1rem 1rem 3rem 1rem;
  }
}

@media screen and (min-width: 2001px) {
  .portfolio__footer-content{
    padding-top: 400px;
  }

  .portfolio__footer p {
    font-weight: 300;
    font-size: 20px;
    line-height: 160%;
  }

  .portfolio__footer h1 {
    font-weight: 400;
    font-size: 24px;
  }
}
