.transition {
    transition: all 0.3s ease-in-out;
}



.portfolio__menu-container{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap:25px;
    cursor: pointer;
}

.portfolio__MenuItem p{
    transition: 0.5s;
}


.portfolio__menu{
    text-align: center;
    padding-top: 5rem;
}

.portfolio__menu h1{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 30px;
    padding-bottom: 2rem;
}


@media screen and (min-width: 2001px) {
    .portfolio__menu h1{
        font-size: 40px;
    }

    .portfolio__menu p{
        font-size: 20px;
    }
}