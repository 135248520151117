* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  --schysst-color: #FCEF4F;
  --plick-color: #E9FF5F;
  --sustainAx-color: ##FF852D;
  --pathpilot-color: #ffbeb9;
  --its-color: #6BDF7B;
  --sustainax-color: #FFA551;
  --ove-color: #ECD3FF;
  
}

a {
  color: unset;
  text-decoration: none;
}
#spotlight{
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgb(5, 63, 255) 0%, 40%, #cfecf600 90%);
  border-radius: 100%;
  mix-blend-mode:screen;
  position: absolute;
  margin-top: -150px;
  z-index: 0;
  pointer-events: none;
  transform: translateX(-50%);
}
.section__padding{
  padding: 4rem 6rem; 
}

.section__margin{
  margin: 4rem 6rem; 
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@media screen and (max-width: 700px){
  .section__padding{
    padding: 4rem; 
  }

  .section__margin{
    margin: 4rem;
  }
}

@media screen and (max-width: 550px){
  .section__padding{
    padding: 4rem 2rem; 
  }

  .section__margin{
    margin: 4rem 2rem;
  }
}



.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.chip_container{
  display: flex;
  flex-wrap:wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 0.3rem;
}

#ingress{
  font-size: 1.2rem;
}

#quote{
  font-weight: 300;
  font-size: 2.5rem;
  padding: 0rem 5rem 0rem 5rem;
}

@media  screen and (max-width: 650px) {
  #quote{
    padding: 0rem 0rem 0rem 0rem;
    font-size: 20px;
  }
}

@media  screen and (max-width: 1150px) {
  #spotlight{
    display: none;
  }
}

/* Laddningsskärmens stil */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #12c2e9, #c471ed, #f64f59);
  background-size: 400% 400%;
  animation: gradientMove 4s ease infinite;
  z-index: 9999; /* Så att den täcker hela sidan */
  transition: opacity 2s ease; /* Övergång på 2 sekunder */
  opacity: 1; /* Fullt synlig från start */
}

/* Gradient animation */
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Döljer innehållet medan laddningsskärmen visas */
.content {
  display: none;
}



