h1{
  font-weight: 500;
  font-size: 28px;
  font-family: var(--font-family);
}

h2{
  padding-bottom: 1rem;
  font-weight: 500;
  font-size: 20px;
  font-family: var(--font-family);
}

#edu_intro{
    font-weight: 300;
    font-size: 16px;
    font-family: var(--font-family);
    margin-bottom: 3rem;
    width: 45rem;
}
#resume_link{
  font-weight: 500;
}


.portfolio__education{
  padding: 5rem 15rem;
}


table {
    font-family:var(--font-family);
    border-collapse: collapse;
    width: 100%;
    border: 0;
    background-color: #FBF7F2;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4rem;
    color: rgba(0, 0, 0, 0.853);
    
  }
  
  

  tr:nth-child(even){
    background-color: #fff;
  }

  td, th {
    text-align: left;
    padding: 1.5rem;
   
  }
  

  @media  screen and (max-width: 1250px) {
    .portfolio__education{
      padding: 2rem 5rem;
    }
    #points{
      width: 6rem;
    }
    #edu_intro{
      width: auto;
  }
}

@media  screen and (max-width: 700px) {

  h1{
    font-size: 24px;
  }
  h2{
    font-size: 16px;
  }
  #edu_intro{
    font-size: 14px;
  }
  .portfolio__education{
    padding: 1rem 1rem;
  }

  table{
    font-size: 12px;
  }

  #hide{
    display: none;
  }
}

@media  screen and (max-width: 550px) {
  .portfolio__education{
    padding: 1rem 0.75rem;
  }  
}

  