#go_back{
    margin-top: 0;
    font-size: 14px;
    text-decoration: underline;

}

.portfolio__projects-left_back{
    margin-left: 5rem;
    margin-top: 2rem;
}


.portfolio__projects h3{
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0.5rem;
}


.portfolio__projects {
    font-family: var(--font-family);
    display: flex;
    flex-direction: row;
    vertical-align: middle;
}

.portfolio__projects-left{
    min-height: 100vh;
    width: 50vw;
    display: flex;
    align-items:center;
    justify-content:center;
    vertical-align: middle;
}

.portfolio__projects-left_content{
    margin-left: 5rem;
    margin-right: 5rem;
    vertical-align: middle;
}

.portfolio__projects-right{
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    width: 50vw;
}

.portfolio__projects-right #schysst_nr1{
    background: linear-gradient(203.88deg, #373737 1.87%, #000000 126.04%);
}

.portfolio__projects-left_content h1{
    font-weight: 500;
    font-size: 34px;
    margin-bottom: 1rem;
}

.portfolio__projects-left_content h2{
    font-weight: 500;
    font-size: 18px;
}

.portfolio__projects-left_content p {
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 3rem;
}



/**-------schysst käk-----------*/
#schysst1{
    background-color: #FCEF4F;
}

#schysst1_img{
   width: 20rem;
}

#schysst2{
    background-color: #F9F1E7;
}
#schysst2 img{
    width: 80%;
}

#schysst3{
    background-color: #F9F1E7;
}

#schysst3 img{
   width: 80%;
   filter: drop-shadow(0 0 0.45rem rgba(0, 0, 0, 0.10));
}

#schysst4{
    background-color: #FCEF4F;
}

#schysst4 img{
    width: 40%;
}

#schysst5{
    background-color: #F9F1E7;
}

#schysst5 img{
    width: 15rem;
}
#schysst6{
    background-color: #F9F1E7;
}

#schysst6 img{
    width: 70%;
}

/**-------solwe-----------*/

#solwe1{
    background-color: #CFECF6;
}

#solwe1_img{
    width: 15rem;
}

#solwe2 img{
    object-fit: cover;
}


/**-----------Gymtrack----------*/
#gymtrack1 img{
    width: 20rem;
   

}



#gymtrack1 {
    background-color: #E7EBED;
}

#gymtrack2{
    background-size: cover;
}

#gymtrack3 img{
    width: 30rem;
}

#gymtrack3 {
    background-color: #E7EBED;
}

#gymtrack4 {
    background-color: #E7EBED;
}

#gymtrack4 img{
    width: 50vh;
}

#gymtrack5 {
    background-color: #E7EBED;
}

#gymtrack5 img{
    width: 25rem;
}

#gymtrack6 {
    background-color: #E7EBED;
}

#gymtrack6 img{
    width: 50vh;
}


/*--------------Naturglass-------------*/

#naturglass{
    background: linear-gradient(204.89deg, rgba(255, 240, 200, 0.63) 2.03%, #FFFADF 2.04%, #FEF5C4 101.31%);
}

.naturglass_image{
    justify-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.naturglass_image img{
    width: 80vw;
}

#naturglass img{
    width: 25rem;
}






/*--------------Ove-------------*/
#ove{
    background: linear-gradient(203.88deg, #373737 1.87%, #000000 126.04%);
}

#ove img{
    width: 20rem;
    filter: drop-shadow(0 0 0.45rem rgba(0, 0, 0, 0.10)); 
}



/*-----------------Coetus----------*/
#coetus{
    background: linear-gradient(203.88deg, #8FE0E7 1.87%, #52B4BC 126.04%);

}

#coetus img{
    width: 20rem;
}

#coetus2{
    object-fit: cover;
}



/*_____________DataTjej-------------*/

#datatjej1{
    background: linear-gradient(203.88deg, #373737 1.87%, #000000 126.04%);
}






@media  screen and (max-width: 1200px) {
    .portfolio__projects{
        flex-direction: column;       
    }

    .portfolio__projects-left{
        flex-direction: column;
        width: 100vw;
        min-height: 50vh;
        margin-top: 3rem;
    }

    .portfolio__projects-left_content{
        flex-direction: column;
        margin-left: 5rem;
        margin-right: 10rem;
        vertical-align: middle;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    #reverse{
        flex-direction: column-reverse;
    }

    .portfolio__projects-right{
        width: 100vw;
        min-height: 70vh;
    }

    #datatjej2{
        display: none;
    }
}





@media  screen and (max-width: 950px) {
    .portfolio__projects{
        flex-direction: column;
        padding-right: 0;
        width: 100vw;
    }

    .portfolio__about{
        padding: 0;
        gap: 1rem;
    }

    .portfolio__about img {
        margin-left: 5rem;
    }

    .portfolio__projects-left_content{
        padding:0;
        vertical-align: middle;
        margin: 10rem 5rem 5rem 5rem;
    }

    #gymtrack1{
        flex-direction: row-reverse;
    }
    #reverse{
        flex-direction: column-reverse;
    }
}


@media  screen and (max-width: 650px) {

    .portfolio__projects-left{
        vertical-align: middle;
    }

   
    
    .portfolio__projects-left_content{
        flex-direction: column;
        padding:0;
        vertical-align: middle;
        margin-left: 2rem;
        margin-right: 2rem;
        vertical-align: middle;
        margin-top: 3rem;
        
    }
    
    .portfolio__projects-left_content h2{
        font-size: 1rem;
       
    }

    .portfolio__projects-left_content h1{
        font-weight: 500;
        font-size: 24px;
       
    }
    
    .portfolio__projects-left_content p {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
    }

    #gymtrack3 img{
        width: 20rem;
    }

   
.naturglass_image img{
    width: 95vw;
}
  
}

@media screen and (min-width: 2001px) {
    .portfolio__projects p {
        font-size: 24px;
    }

}



