@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

:root {
  --font-family: 'Poppins';
  transition: 0.5s;
}

a:hover{
    opacity: 0.6;
}

.portfolio__navbar {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    position: fixed;
    background-color: inherit;
    z-index: 1;
    width: 100%;
}

.portfolio__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.portfolio__navbar-links_logo{
    margin-right: 2rem;
}

.portfolio__navbar-links_logo img{
    width: 62px;
    height: 16;
    transition: filter 1.5s ease; /* Lägg till övergångseffekter */
} 

.portfolio__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.portfolio__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.portfolio__navbar-links_container p, 
.portfolio__navbar-sign p, 
.portfolio__navbar-menu_container p{
    color:var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}


.portfolio__navbar-sign button, 
.portfolio__navbar-menu_container button{
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    border-radius: 50px;
    border:none;
    outline: none;
    cursor: pointer;
    background-color: black;
    color: white;
} 

button:hover{
    border: 1px solid #000;

}

.portfolio__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.portfolio__navbar-menu svg{
    cursor: pointer;
}

.portfolio__navbar-menu_container{
    display: flex; 
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: black;
    color: white;
    padding: 2rem; 
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    max-width: 210px;
    border-radius: 5px; 
    box-shadow:  0 0 5 rgba(0,0,0,0.2);

}

.portfolio__navbar-menu_container p {
    margin: 1rem 0;
}

.portfolio__navbar-menu_container-links-sign{
    display: none;
}

@media  screen and (max-width: 1050px) {
    .portfolio__navbar-links_container{
        display: none;
    }

    .portfolio__navbar-menu{
        display: flex;
    }
}

@media  screen and (max-width: 700px) {
    .portfolio__navbar{
      padding: 2rem 4rem;
    }
}

@media  screen and (max-width: 550px) {
    .portfolio__navbar{
      padding: 2rem;
    }

    .portfolio__navbar-sign{
        display: none;
    }
}

.portfolio__navbar-menu_container{
    top: 20px;
}

.portfolio__navbar-menu_container-links-sign{
    display: block;
}


