
.chip-general{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}


.chip{
    padding: 8px 16px;
    border-radius: 26px;
    width: fit-content;
   
}

.chip-small{
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 6px;
    width: fit-content;
    font-family: poppins;

}

.chip-icon{
    height: 16px;
    align-self: center;   
    gap: 0.5rem;
}

.chip-small img{
    height: 16px;
}


.chip-stroke{
    border: 1px solid black;
}

.chip-rounded {
    border-radius: 106px;
}

