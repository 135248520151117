.portfolio{
    align-self: center;
    display: flex;
    justify-self: center;
}

.faq-container {
    margin-top: 1000px;
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    padding: 30px;
    padding-bottom: 200px;
    font-family: var(--font-family);
  }

  .faq-container h1 {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 1.3rem;
  }

  .faq {
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.1s ease;
  }
  
  .faq:hover {
   
    .faq-question{
        font-weight: 800;
    }
  }
  
  .faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1rem;
    transition: all 0.4s ease;
  }

  .faq-answer {
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
    font-weight: 300;
    transition: all 0.2s ease;
    font-weight: 400;
    margin-right: 50px;
  }
  
  .faq.open .faq-answer {
    max-height: 700px; /* Anpassa höjden efter behov */
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 1; /* Visa svaret när frågan är öppen */
  }

  .faq.open .faq-question{
    font-weight: 800;
  }
  .faq-plus-icon {
    font-size: 16px; /* Justera storleken på ikonen */
    width: 16px;
    height: 16px;
    margin-left: 10px; /* Justera avståndet mellan text och ikon */
    flex-shrink: 0; /* Förhindrar att ikonen krymper när utrymmet blir tight */
  }
  
  .faq-divider {
    border: none;
    border-top: 1px solid black;
    margin: 10px 0;
  }
  
  @media screen and (min-width: 2001px) {
    .faq-question{
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 900px) {
    .faq-question{
      font-size: 1rem;
    }

    .faq-answer{
      font-size: 0.85rem;
    }
    
    .faq-answer{
      margin-right: 0;
    }
  }
  
  