.backArrow{
    background-color: black;
    border-radius: 1000px;
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: fixed;
    top: 2rem;
    left: 1rem;
    cursor: pointer;
    transition: all ease-in-out 0.3;
}



.backArrow:hover{
    font-size: 1.3rem;
    transition: all ease-in-out 0.3;
}