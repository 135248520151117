.portfolio__volunteer{
    display: flexbox;
    justify-content: center;
    font-family: var(--font-family);
    padding: 13rem 20rem 0rem 20rem;
    flex-direction: row; 
    align-items: center;
}

.project{
    margin-top: 1rem;
    
}

h1{
    font-size: 28px;
    font-weight: 500;
}

.project_info{
    display: flex;
    margin-top: 3rem;
    max-width: 50rem;
    justify-content: space-between;
}

.project_title{
    max-width: 50vw;
}

h2{
    font-size: 16px;
    padding-bottom: 0;
}

h3{
    font-size: 16px;
    padding-bottom: 0;
    font-weight: 400;
}
@media  screen and (max-width: 1200px) {
    .portfolio__volunteer{
        display: flexbox;
        justify-content: center;
        font-family: var(--font-family);
        padding: 10rem 10rem 1rem 10rem;
        flex-direction: row; 
        cursor: pointer; /* Visa pekaren när man hovrar över bilden */
        align-items: center;
    }
}


@media  screen and (max-width: 950px) {
    .portfolio__volunteer{
        display: flexbox;
        justify-content: center;
        font-family: var(--font-family);
        padding: 10rem 1rem 1rem 1rem;
        flex-direction: row; 
        cursor: pointer; /* Visa pekaren när man hovrar över bilden */
        align-items: center;
    } 
}

@media  screen and (max-width: 650px) {
    .portfolio__volunteer{
        display: flexbox;
        justify-content: center;
        font-family: var(--font-family);
        padding: 10rem 1rem 1rem 1rem;
        flex-direction: row; 
        cursor: pointer; /* Visa pekaren när man hovrar över bilden */
        align-items: center;
    }

    h1{
        font-size: 16px; 
    }
    h2{
        font-size: 12px;
    }

    h3{
        font-size: 12px;
    }
    .project_title{
        max-width: 80vw;
    }
    

}