.portfolio_ab{
    width: 100%;
    min-height: 100vh;
    position: relative;
    transition: background-color 1.5s ease, color 1.5s ease; /* Lägg till övergångseffekter */
}


#krull{
    width: 100%;
    position: absolute;
    margin-top: -60px;
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
    transition: filter 1.5s ease; /* Lägg till övergångseffekter */
}



.portfolio__about{
    display: flex;
    justify-content: center;
    font-family: var(--font-family);
    padding: 10rem 10rem 10rem 10rem;
    gap: 8rem;
    flex-direction: row; 
    cursor: pointer; /* Visa pekaren när man hovrar över bilden */
    align-items: center;
}

.portfolio__about img {
    object-fit: contain; /* or cover */
    width: 48%;
    height: auto;
    opacity: 1; /* Första bilden är synlig från början */
    transition: opacity 1.5s ease, color 1.5s ease; /* Lägg till övergångseffekter */
}

.portfolio__about p {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 160%;
}

.portfolio__about h1 {
    font-weight: 400;
    padding-bottom: 2rem ;
    font-size: 2rem;
    
}

.portfolio__about h2 {
    font-weight: 400;
    text-decoration: underline;
    font-size: 18px;
    padding-top: 3rem;
    cursor: pointer;
}

@media screen and (min-width: 2001px) {
    .portfolio__about p {
        font-size: 24px;
    }

    .portfolio__about h1 {
        font-size: 54px;
    }

    .portfolio__about{
        padding: 15rem 20rem 5rem 20rem;
        gap: 7rem;
    }
}

@media  screen and (max-width: 1200px) {
    .portfolio__about{
        gap: 5rem;
        padding: 15rem 5rem 5rem 5rem;
    }
    .portfolio__about-content p{
        font-size: 1rem;
    }
    .portfolio{
        min-height: 160vh;
    }
    .portfolio__about img {
        object-fit: contain; /* or cover */
        width: 50vh;
        height: auto;  
    }
}

@media  screen and (max-width: 950px) {
    .portfolio__about-content{
    
        margin: 10rem 5rem 5rem 5rem;
    }
    .portfolio__about{
        flex-direction: column;
        padding: 0;
        gap: 1rem;
    }
    .portfolio__about img {
        margin: 0;
        width: 80%;
    }
}


@media  screen and (max-width: 650px) {
    .portfolio__about{
        padding: 10rem 0rem 0rem 0rem;
        gap: 1rem;
    }
    .portfolio__about-content{
        margin: 4rem 1rem 1rem 1rem;
    }  

    .portfolio__about img {
        width: 90%;

    }
    .portfolio__about h2 {
        padding-top: 3rem;
    }
}



@media  screen and (max-width: 375px) {
    .portfolio{
        min-height: 180vh;
    }
}

#style1 {
    background-color: white;
    color: black;
    .faq-divider, .portfolio__navbar-sign button, .portfolio__navbar-menu_container button{
        background-color: black;
        color: white;
        border-top: 1px solid black;
    }
}

#style2 {
    background-color: #FAEEE0;
    color: #4A4235;
    #spotlight{
        background: #ebfff2;
        mix-blend-mode:exclusion;
        border-radius: 100%;
        position: absolute;
        margin-top: -150px;
        z-index: 0;
        pointer-events: none;
        transform: translateX(-50%);
      }
    .faq-divider, .portfolio__navbar-sign button, .portfolio__navbar-menu_container button #spotlight{
        background-color: #4A4235;
        color: #FAEEE0;
        border-top: 1px solid #4A4235;
    }
    #krull,  .portfolio__navbar-links_logo img, #smiley{
        filter: invert(23%) sepia(35%) saturate(283%) hue-rotate(358deg) brightness(98%) contrast(91%);
    }
}

#style3 {
    background-color: #ECDDFE;
    color: #FF5500;
    .faq-divider, .portfolio__navbar-sign button, .portfolio__navbar-menu_container button #spotlight{
        background-color: #FF5500;
        color: #ECDDFE;
        border-top: 1px solid #FF5500;
    }
    #krull, .portfolio__navbar-links_logo img, #smiley{
        filter: invert(37%) sepia(34%) saturate(4428%) hue-rotate(1deg) brightness(103%) contrast(106%);
    }
}


#style4 {
    background-color: #E5F2FF;
    color: #BB2511;
    #spotlight{
        background: radial-gradient(circle, rgb(5, 63, 255) 0%, 40%, #cfecf600 90%);
        mix-blend-mode: screen;
        border-radius: 100%;
        position: absolute;
        margin-top: -150px;
        z-index: 0;
        pointer-events: none;
        transform: translateX(-50%);
      }

    .faq-divider, .portfolio__navbar-sign button, .portfolio__navbar-menu_container button #spotlight{
        background-color: #BB2511;
        color: #E5F2FF;
        border-top: 1px solid #BB2511;
    }

    #krull, .portfolio__navbar-links_logo img, #smiley{
        filter: invert(18%) sepia(88%) saturate(2212%) hue-rotate(352deg) brightness(99%) contrast(99%);
    }
}

#style5 {
    background-color: #FFE8AB;
    color: #0C3406;

    #spotlight{
        background: radial-gradient(circle, #f7d884 0%, 2%, #ffe9ab00 60%);
        mix-blend-mode: multiply;
        border-radius: 100%;
        position: absolute;
        margin-top: -150px;
        z-index: 0;
        pointer-events: none;
        transform: translateX(-50%);
      }
    .faq-divider, .portfolio__navbar-sign button, .portfolio__navbar-menu_container button #spotlight{
        background-color: #0C3406;
        color: #FFE8AB;
        border-top: 1px solid #0C3406;
    }
    #krull, #smiley{
        filter: invert(14%) sepia(37%) saturate(1555%) hue-rotate(73deg) brightness(93%) contrast(97%);    }
    .portfolio__navbar-links_logo img{
        filter: invert(14%) sepia(37%) saturate(1555%) hue-rotate(73deg) brightness(93%) contrast(97%);    }
}

#style6{
    background-color: #FFC5C5;
    color: #155620;

    #spotlight{
        background: radial-gradient(circle, #155620 0%, 2%, #ffe9ab00 60%);
        mix-blend-mode: exclusion;
        border-radius: 100%;
        position: absolute;
        margin-top: -150px;
        z-index: 0;
        pointer-events: none;
        transform: translateX(-50%);
      }

    .faq-divider, .portfolio__navbar-sign button, .portfolio__navbar-menu_container button #spotlight{
        background-color:  #155620;
        color:  #FFC5C5;
        border-top: 1px solid  #155620;
    }
    #krull, #smiley{
        filter: brightness(0) saturate(100%) invert(28%) sepia(21%) saturate(1605%) hue-rotate(78deg) brightness(85%) contrast(93%);
    }    
    .portfolio__navbar-links_logo img{
        filter: brightness(0) saturate(100%) invert(28%) sepia(21%) saturate(1605%) hue-rotate(78deg) brightness(85%) contrast(93%);
    }    
}

#style7 {
    background-color: #1A1B41;
    color: #F5FBEF;

    #spotlight{
        background: radial-gradient(circle, #F5FBEF 0%, 90%, #ffffff00 100%);
        mix-blend-mode:difference;
        border-radius: 100%;
        position: absolute;
        margin-top: -150px;
        z-index: 0;
        pointer-events: none;
        transform: translateX(-50%);
      }
    .faq-divider, .portfolio__navbar-sign button, .portfolio__navbar-menu_container button{
        background-color: #F5FBEF;
        color: #1A1B41;
        border-top: 1px solid #F5FBEF;
    }
    #krull,  .portfolio__navbar-links_logo img, #smiley{
        filter: invert(89%) sepia(2%) saturate(2912%) hue-rotate(55deg) brightness(121%) contrast(95%);
    }
}