.portfolio__projects-solutions p{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
}
.portfolio__projects-solutions h1{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 34px;
    padding-bottom: 1rem;
}
.portfolio__projects-solutions{
    align-items: center;
    text-align: center;
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.portfolio__projects-solutions_text{
    padding-left: 15rem;
    padding-right: 15rem;
    padding-bottom: 5rem;
}
.portfolio__projects-solutions_img{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.portfolio__projects-solutions_img img {
    width: 20rem;
}




@media  screen and (max-width: 950px) {
    .portfolio__projects-solutions{
        flex-direction: column;       
    }

    .portfolio__projects-solutions_text{
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 5rem;
    }

    .portfolio__projects-solutions_img img{
        
        width: 30%;
    }
}


@media  screen and (max-width: 550px) {
    .portfolio__projects-solutions{
        flex-direction: column;       
    }

    .portfolio__projects-solutions_text{
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 2rem;
    }

    .portfolio__projects-solutions_img{
       flex-direction: column;
    }
    .portfolio__projects-solutions_img img{
        
        width: 80%;
    }
}